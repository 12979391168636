<template>
  <div class="ep-stop-pwd-manager">
    <div class="ep-stop-pwd-manager__content">
      <h3 style="color: #313195;">{{ $t('homepage.alert.pwdManager.title') }}</h3>
      <br>
      {{ $t('homepage.alert.pwdManager.content') }}
    </div>
    <div class="ep-stop-pwd-manager__close" @click="$emit('close-pwdmanager')">
      <EuiIcon name="close"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'stopPwdManager',
};
</script>

<style lang="scss" scoped>
.ep-stop-pwd-manager {
  margin: 2rem 4rem 0 4rem;
  display: flex;
  padding: 1rem 1rem 1rem;
  height: auto;
  background-color: #d7d7ff;
  border: 0.125rem solid #313195;

  &__close {
    margin: auto auto auto 2rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #313195;
  }

  &__content {
    color: #313195 !important;
    width: auto;
    padding: 0.5rem;
  }
}
</style>
