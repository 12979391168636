<template>
  <!-- eslint-disable max-len -->
  <ep-layout class="el-body home" v-if="user">
    <stopPwdManager v-if="isPwdManagerOpen" @close-pwdmanager="closePwdManager"/>
    <popinRGPD v-if="user.agreedToLastCGU === false"
               v-on:all-conditions-agreed="sendAgreedCGU()" />
    <el-add-collector-dialog
      :collectorToAdd="collectorToAdd"
      @closeDialog="collectorToAdd = undefined"
      v-if="collectorToAdd"
    />
    <el-delete-collector-dialog
      :collectorToDelete="collectorToDelete"
      @closeDialog="collectorToDelete = undefined"
      v-if="collectorToDelete"
    />
    <div class="el-panel">
      <!-- toolbar -->
      <el-toolbar :sticky="true">
        <h2 class="el-main-title" v-if="user" translate="no">
          {{ $t('homepage.title') }} {{ user.firstname }} {{ user.lastname }}
        </h2>
      </el-toolbar>

      <!-- stat panel -->
      <div class="el-panel--center" v-if="statsLoading">
        <div class="el-loader el-loader__small">
          <span class="el-loader--dot"></span>
          <span class="el-loader--dot"></span>
          <span class="el-loader--dot"></span>
        </div>
      </div>
      <el-stats-panel :stats="stats" @caseClicked="goTo" v-else />
      <div>
        <div class="el-panel--col">
          <div class="el-panel el-panel--col-50">

            <!-- Aide en ligne  -->
            <el-toolbar class='panel-help flex-row'>
              <div>
                <h3 class="el-title panel-help__title">{{ $t('help.title') }}</h3>
                <a :href="supportURL" target="__blank">
                  <el-rectangle-button-container class="panel-help__button pl-0" :grey="true">
                      <el-rectangle-button>
                        {{ $t('help.button') }}
                      </el-rectangle-button>
                  </el-rectangle-button-container>
                </a>
              </div>
              <div class="ml-5" v-if="!this.user.isLinkedToFranceConnect">
                <h3 class="el-title panel-help__title">{{ $t('franceconnect.title') }}</h3>
                <el-rectangle-button-container class="pl-0" :grey="true">
                  <el-rectangle-button @click.native="$router.push({ name: 'profile.index', params:{ isFranceConnectOpened: true } })">
                    {{ $t('franceconnect.button') }}
                  </el-rectangle-button>
                </el-rectangle-button-container>
              </div>
            </el-toolbar>

            <!-- latest documents -->
            <el-toolbar>
              <h3 class="el-title">{{ $tc('homepage.lastDoc', lastDoc.length) }}</h3>
              <el-rectangle-button-container :grey="true" name="latestDocuments">
                <el-rectangle-button
                  @click.native="$router.push({ name: 'folders.index' })"
                >{{ $t('button.seeAll') }}</el-rectangle-button>
              </el-rectangle-button-container>
            </el-toolbar>
            <el-table v-if="lastDoc.length">
              <el-table-body>
                <el-table-row v-for="doc in lastDoc" :key="doc.id" @click="goToDocs(doc)">
                  <el-table-cell data-type="input">
                    <span
                      :class="['icon-document', 'icon--orange--table', { 'icon--notif': doc.isNew }]"
                    ></span>
                  </el-table-cell>
                  <el-table-cell :main="true">{{ doc.name }}</el-table-cell>
                </el-table-row>
              </el-table-body>
            </el-table>
            <div class="el-loader el-loader__small" v-if="documentsLoading">
              <span class="el-loader--dot"></span>
              <span class="el-loader--dot"></span>
              <span class="el-loader--dot"></span>
            </div>
            <p
              class="el-subtitle"
              v-if="!lastDoc.length && !documentsLoading"
            >{{ $t('documents.toolbar.search.no-doc') }}</p>


          </div>
          <div class="el-panel el-panel--col-50">
            <!-- collectors -->
            <el-toolbar>
              <h3 class="el-title">{{ $t('collector.title') }}</h3>
              <el-rectangle-button-container :grey="true" name="collectors">
                <!-- route à adapter en focntion du name choisi -->
                <el-rectangle-button
                  @click.native="$router.push({ name: 'collectors' })"
                >{{ $t('button.seeAll') }}</el-rectangle-button>
              </el-rectangle-button-container>
            </el-toolbar>

            <!-- cards -->
            <div class="el-panel--card" v-if="!collectorsLoading">
              <el-card v-for="collector in collectorsToDisplay.slice(0, 6)" :key="collector.id">
                <div class="ep-card__img-container" v-if="collector.logo">
                  <img class="ep-card__img" :src="'data:image/jpeg;base64,' + collector.logo" :alt="'image ' + collector.name"  aria-hidden="true"/>
                </div>
                <el-card-icon class="icon-collecteur" v-else />
                <b>
                  <span v-if="collector.hasError" class="icon-a_valider" style="color: red;" :aria-label="$t('collector.asideMenu.error') + ' ' + collector.name"></span>
                  <span aria-hidden="true"> {{ collector.name.slice(0, 19)}}</span>
                  <span v-if="collector.name.length > 19" aria-hidden="true" >...</span>
                </b>
                <button
                  class="el-button el-button--small"
                  @click="editCollector(collector)"
                  :title="$t('collector.card.see') + ' ' + collector.name"
                  :aria-label="$t('collector.card.see') + ' ' + collector.name"
                >{{ $t('collector.card.see') }}</button>
                <el-card-hover-panel>
                  <el-card-hover-button
                    v-if="!collector.hasError"
                    class="icon-play"
                    @click.native="loadFiles(index)"
                    :title="$t('collector.card.activate') + ' ' + collector.name"
                    :aria-label="$t('collector.card.activate') + ' ' + collector.name"
                  />
                  <el-card-hover-button
                    v-else
                    class="icon-a_valider"
                    @click.native="editCollector(collector)"
                    :title="$t('collector.card.edit') + ' ' + collector.name"
                    :aria-label="$t('collector.card.edit') + ' ' + collector.name"
                  />
                </el-card-hover-panel>
              </el-card>
            </div>
            <div class="el-loader el-loader__small" v-if="collectorsLoading" >
              <span class="el-loader--dot"></span>
              <span class="el-loader--dot"></span>
              <span class="el-loader--dot"></span>
            </div>
            <p
              class="el-subtitle"
              v-if="!collectors.length && !collectorsLoading"
            >{{ $t('collector.toolbar.no-match') }}</p>
          </div>
        </div>

      </div>
    </div>
  </ep-layout>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import EpLayout from '@/layouts/Default.vue';
// import EpDocuments from '../documents/Index';
import addCollecteurDialog from '@/pages/collectors/add.vue';
import deleteCollecteurDialog from '@/pages/collectors/delete.vue';
import popinRGPD from '../../components/popinRGPD.vue';
import stopPwdManager from '../../components/stopPwdManager.vue';
import auth from '../../utils/auth';

export default {
  data() {
    return {
      isPwdManagerOpen: true,
      n: null,
      stats: [
        {
          label: '',
          nb: 0,
          total: 0,
          color: 'light-blue',
        },
        {
          label: '/',
          nb: 0,
          total: 0,
        },
        {
          label: '',
          nb: 0,
          total: 0,
          color: 'yellow',
        },
      ],
      collectorToAdd: undefined,
      collectorToDelete: undefined,
      statsLoading: true,
      collectorsLoading: true,
      documentsLoading: true,
      conditionsAgreed: undefined,
      supportURL: 'https://support-edocperso.zendesk.com/hc/fr',
    };
  },
  components: {
    EpLayout,
    // EpDocuments,
    'el-add-collector-dialog': addCollecteurDialog,
    'el-delete-collector-dialog': deleteCollecteurDialog,
    popinRGPD,
    stopPwdManager,
  },
  watch: {
    collectors() {
      if (this.collectorToAdd) {
        this.collectorToAdd = this.collectors.find(
          x => x.id === this.collectorToAdd.id,
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      usageDatas: 'user/usageDatas',
      collectors: 'collector/userCollectors',
      lastDoc: 'documents/lastDoc',
      petalCounter: 'user/petalCounter',
      documents: 'documents/documents',
    }),
    ...mapState('auth', {
      cgu: state => state.session.askForNewCgu,
      isMIPIH: state => state.isMIPIH,
    }),
    collectorsToDisplay() {
      const sortedCollectors = [];
      this.collectors.forEach((collector) => {
        let isError = false;
        collector.subscriptions.forEach((subscription) => {
          if (subscription.retrievalStatus !== 'collectingDocs'
            && subscription.retrievalStatus !== 'retrievingDocs'
            && subscription.retrievalStatus !== ''
            && subscription.retrievalStatus !== 'unknow') isError = true;
        });
        if (isError) {
          // eslint-disable-next-line no-param-reassign
          collector.hasError = true;
          sortedCollectors.unshift(collector);
        } else {
          // eslint-disable-next-line no-param-reassign
          collector.hasError = false;
          sortedCollectors.push(collector);
        }
      });
      return sortedCollectors;
    },
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      updateCGU: 'user/updateCGU',
      getLastDoc: 'documents/getLastDoc',
      getDocuments: 'documents/all',
      getCollectors: 'collector/getUserCollectors',
      getUsageDatas: 'user/getUsageDatas',
      setBreadcrumbs: 'application/setBreadcrumbs',
      setPetalsCounter: 'user/setPetalsCounter',
      getSubscriptionsForProvider: 'collector/getSubscriptionsForProvider',
    }),

    closePwdManager() {
      this.isPwdManagerOpen = false;
      localStorage.setItem('ep-stop-pwd-manager', 'true');
    },

    goTo(value) {
      if (value === this.$i18n.tc('homepage.stats.sharedDoc', 1) || value === this.$i18n.tc('homepage.stats.sharedDoc', 2)) {
        this.$router.push({ name: 'sharings.index' });
      } else {
        this.$router.push({ name: 'folders.index' });
      }
    },

    goToDocs(doc) {
      this.$router.push({
        name: 'document.details',
        params: { doc },
      });
    },

    editCollector(col) {
      this.addPopupIsVisible = true;
      this.getSubscriptionsForProvider({ providerId: col.id }).then(() => {
        this.collectorToAdd = col;
      });
    },

    loadFiles(index) {
      const subscriptionsIds = [];
      this.collectorsToDisplay[index].subscriptions.forEach((subscription) => {
        subscriptionsIds.push(subscription.subscriptionId);
      });
      this.loadFilesAction({
        providerId: this.collectorsToDisplay[index].id,
        subscriptionIds: subscriptionsIds,
      });
    },
    initStats() {
      // init stats panel with values
      // unread docs
      this.stats[0].nb = this.usageDatas.nbNewDocs;
      this.stats[0].label = this.$i18n.tc('homepage.stats.unreadDoc', this.stats[0].nb);
      this.stats[0].total = this.usageDatas.totalNbDocs;
      // storage available --> convert to o, Mo, Ko or Go according to value
      if (
        Math.floor(this.usageDatas.usedStorageSize / (1024 * 1024 * 1024)) > 0
      ) {
        this.stats[1].total = this.usageDatas.totalStorageSize / (1024 * 1024 * 1024);
        // eslint-disable-next-line
        this.stats[1].nb =
          Math.round(
            (this.usageDatas.usedStorageSize / (1024 * 1024 * 1024)) * 100,
          ) / 100;
        this.stats[1].label = this.$i18n.t('homepage.stats.storage.GB');
      } else if (
        Math.floor(this.usageDatas.usedStorageSize / (1024 * 1024)) > 0
      ) {
        this.stats[1].total = this.usageDatas.totalStorageSize / (1024 * 1024);
        // eslint-disable-next-line
        this.stats[1].nb =
          Math.round((this.usageDatas.usedStorageSize / (1024 * 1024)) * 100)
          / 100;
        this.stats[1].label = this.$i18n.t('homepage.stats.storage.MB');
      } else if (Math.floor(this.usageDatas.usedStorageSize / 1024) > 0) {
        this.stats[1].total = this.usageDatas.totalStorageSize / 1024;
        this.stats[1].nb = Math.round((this.usageDatas.usedStorageSize / 1024) * 100) / 100;
        this.stats[1].label = this.$i18n.t('homepage.stats.storage.KB');
      } else {
        this.stats[1].total = Math.round(this.usageDatas.totalStorageSize);
        this.stats[1].nb = Math.round(this.usageDatas.usedStorageSize * 100) / 100;
        this.stats[1].label = this.$i18n.t('homepage.stats.storage.B');
      }
      // sharing in process
      this.stats[2].nb = this.usageDatas.nbActiveSharings;
      this.stats[2].label = this.$i18n.tc('homepage.stats.sharedDoc', this.stats[2].nb);
      this.stats[2].total = this.usageDatas.totalNbSharings;
      // check storage space available and pick a color
      if (this.stats[1].nb >= (this.stats[1].total / 5) * 4) {
        this.stats[1].color = 'red';
      } else if (this.stats[1].nb > (this.stats[1].total / 5) * 3) {
        this.stats[1].color = 'yellow';
      } else {
        this.stats[1].color = 'green';
      }
      this.statsLoading = false;
    },
    sendAgreedCGU() {
      const agreedToLastCGU = true;
      this.updateCGU({ agreedToLastCGU }).then(() => {
        this.getUserInfo();
      });
    },
  },

  mounted() {
    this.isPwdManagerOpen = JSON.parse(localStorage.getItem('ep-stop-pwd-manager')) === null;
    this.conditionsAgreed = !this.cgu;
    if (!this.lastDoc.length) {
      this.getLastDoc(10).then(() => {
        this.documentsLoading = false;
      });
    } else {
      this.documentsLoading = false;
    }
    if (!this.collectors.length) {
      this.getCollectors().then(() => {
        this.collectorsLoading = false;
      });
    } else {
      this.collectorsLoading = false;
    }
    if (!this.usageDatas) {
      this.getUsageDatas().then(() => {
        this.initStats();
      });
    } else {
      this.initStats();
    }
    if (!this.petalCounter) {
      this.setPetalsCounter();
    }
    auth.getConf().then((response) => {
      if (response.CONF.MIPIH) {
        this.supportURL = response.CONF.supportURL;
      }
    });
  },
};
</script>
